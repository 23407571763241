






import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Callout extends Vue {
    @Prop({
        type: String, default: "secondary", validator: (value: string): boolean => [
            "primary", "secondary", "success", "warning", "danger", "info", "light", "dark"
        ].includes(value)
    }) readonly variant!: string;
}
