






















import {Component, Vue} from "vue-property-decorator";
import {BContainer} from "bootstrap-vue/esm";
import {BImg} from "bootstrap-vue";

@Component({components: {BContainer, BImg}})
export default class BottomNav extends Vue {

}
