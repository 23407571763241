import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Integrations from "@/views/integrations/Integrations.vue";
import AmazonIntegration from "@/views/integrations/AmazonIntegration.vue";
import EbayIntegration from "@/views/integrations/EbayIntegration.vue";
import Features from "@/views/Features.vue";
import Pricing from "@/views/Pricing.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/integrations",
        name: "integrations",
        component: Integrations,
        meta: {
            title: "Integrations"
        }
    },
    // {
    //     path: "/integrations/amazon",
    //     name: "amazon",
    //     component: AmazonIntegration
    // },
    // {
    //     path: "/integrations/ebay",
    //     name: "ebay",
    //     component: EbayIntegration
    // },
    // {
    //     path: "/pricing",
    //     name: "pricing",
    //     component: Pricing,
    //     meta: {
    //         title: "Pricing"
    //     }
    // },
    {
        path: "/features",
        name: "features",
        component: Features,
        meta: {
            title: "Features"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        else return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.title != null) document.title = "OrderView - " + to.meta.title;
    else document.title = "OrderView";

    next();
});

export default router;
