




























import {Vue, Component} from "vue-property-decorator";
import {
    BButton,
    BCollapse,
    BContainer,
    BImg,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavbarToggle,
    BNavItem
} from "bootstrap-vue/esm";

@Component({components: {BNavbar, BContainer, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BButton, BImg}})
export default class TopNav extends Vue {
}
