

















































































































    import {Vue, Component} from "vue-property-decorator";
    import Callout from "@/components/Callout.vue";
    import {BButton, BCardGroup, BCard, BCardBody, BCardFooter, BImg} from "bootstrap-vue/esm";

    @Component({components: {Callout, BCardGroup, BCard, BCardBody, BCardFooter, BImg, BButton}})
    export default class Integrations extends Vue {
        orderSourceIntegrations: Integration[] = [
            {key: "ebay", name: "eBay", logoWidth: 1100, logoHeight: 480, missingDarkModeLogo: true},
            {key: "etsy", name: "Etsy", logoWidth: 1200, logoHeight: 600, missingDarkModeLogo: true},
            {key: "woocommerce", name: "WooCommerce", logoWidth: 1678, logoHeight: 339},
        ];

        carrierIntegration: Integration[] = [
            {key: "parcel2go", name: "Parcel2Go", logoWidth: 199, logoHeight: 30, missingDarkModeLogo: true, logoClasses: "p2g-logo"},
            {key: "click-and-drop-api", name: "Royal Mail Click & Drop", logoWidth: 541, logoHeight: 181 },
			{
				key: "transglobal-express",
				name: "Transglobal express",
				logoWidth: 868,
				logoHeight: 162,
				missingDarkModeLogo: true,
				logoClasses: 'transglobal-logo',
			},
        ];
    }

    interface Integration {
        key: string;
        name: string;
        logoWidth: number;
        logoHeight: number;
        logoClasses?: string;
        logoHref?: string;
        comingSoon?: boolean;
        missingDarkModeLogo?: boolean;
    }
