





































































    import {Vue, Component} from "vue-property-decorator";
    import {BButton, BCard, BCol, BImg, BRow} from "bootstrap-vue/esm";
	import {BCardFooter} from 'bootstrap-vue';

    @Component({components: {BCard, BRow, BCol, BImg, BButton, BCardFooter}})
    export default class Home extends Vue {
    }
