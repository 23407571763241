













































































import {Vue, Component} from "vue-property-decorator";
import {BButton, BCard, BCol, BImg, BRow} from "bootstrap-vue/esm";

@Component({components: {BCard, BCol, BRow}})
export default class Features extends Vue {

}
