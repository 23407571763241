import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import '@/assets/scss/style.scss';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
