










import {Vue, Component} from "vue-property-decorator";
import TopNav from "@/components/TopNav.vue";
import BottomNav from "@/components/BottomNav.vue";
import {BContainer} from "bootstrap-vue/esm";

@Component({
    components: {TopNav, BContainer, BottomNav}
})
export default class App extends Vue {
}
